import React from "react"
import styled from "styled-components"

export const Column = ({
  children,
  breakpoints,
  widths = ["auto"],
  padding,
}) => {
  const breakpointOne = breakpoints[0]
  const breakpointTwo = breakpoints.length >= 1 ? breakpoints[1] : null
  const breakpointThree = breakpoints.length >= 2 ? breakpoints[2] : null

  const widthOne = widths[0]
  const widthTwo = widths.length >= 1 ? widths[1] : null
  const widthThree = widths.length >= 2 ? widths[2] : null
  return (
    <Wrapper
      breakpointOne={breakpointOne}
      breakpointTwo={breakpointTwo}
      breakpointThree={breakpointThree}
      widthOne={widthOne}
      widthTwo={widthTwo}
      widthThree={widthThree}
      padding={padding}
    >
      {children}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  flex: 1 1 0;
  width: 100%;
  padding: ${props => props.padding}px;

  // ACTIVE BETWEEN BREAKPOINT ONE AND TWO (OR 9999PX)
  @media (min-width: ${props =>
      props.breakpointOne}px) and (max-width: ${props =>
      props.breakpointTwo | 9999}px) {
    width: ${props =>
      props.widthOne !== "auto" ? `${(props.widthOne / 12) * 100}%` : null};
    flex: ${props => (props.widthOne !== "auto" ? "none !important" : null)};
  }

  // ACTIVE BETWEEN BREAKPOINT TWO AND THREE (OR 9999PX)
  @media (min-width: ${props =>
      props.breakpointTwo}px) and (max-width: ${props =>
      props.breakpointThree | 9999}px) {
    width: ${props =>
      props.widthTwo !== "auto" ? `${(props.widthTwo / 12) * 100}%` : null};
    flex: ${props => (props.widthTwo !== "auto" ? "none !important" : null)};
  }

  // ACTIVE BETWEEN BREAKPOINT THREE AND UP
  @media (min-width: ${props => props.breakpointThree}px) {
    width: ${props =>
      props.widthThree !== "auto" ? `${(props.widthThree / 12) * 100}%` : null};
    flex: ${props => (props.widthThree !== "auto" ? "none !important" : null)};
  }
`
Column.defaultProps = {
  padding: 8,
}
