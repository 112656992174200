import  React  from  'react';
import  styled from  'styled-components';
import { Column } from  './Column';


export const Row = ({children, breakpoints = [769]}) => {
  return (
    <Wrapper breakpoint={breakpoints[0]}>
      {React.Children.toArray(children).map((item) => {
        return (
            item && (
                <Column
                  breakpoints={breakpoints}
                  {...item.props}
                >
                  {item.props.children}
                </Column>
              )
            );
          })}
    </Wrapper>
  );
};

const  Wrapper = styled.div`
@media (min-width: ${props => props.breakpoint}px) {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0 -8px 0 -8px
  }

`;